@primaryColor: #FF854D;
@legiblePrimaryColor: #FF854D;
@lightPrimaryColor: #FCCCB6;
@darkPrimaryColor: #D77446;
@secondaryColor: #291501;
@lightSecondaryColor: #FFD3D2;
@adminColor: #00A0C8;
@lightAdminColor: #E0F4F9;
@borderColor: #C4C4C4;
@inputBackground: white;
@adminHintBackgroundColor: #F6CE46;
@buttonPrimaryShadowColor: #AC451F;
@buttonPrimaryTextColor: white;
@buttonPrimaryHoveredColor: #DB6038;
@buttonPrimaryHoveredTextColor: white;
@buttonPrimaryFocusBorderColor: #DB6038;
@buttonPrimaryActiveColor: #DB6038;
@buttonDisabledBackgroundColor: #CCCCCC;
@buttonDisabledTextColor: #636363;
@buttonPrimaryBasicBackgroundColor: white;
@buttonPrimaryBasicBorderColor: #DB6038;
@buttonPrimaryBasicTextColor: #DB6038;
@buttonPrimaryBasicShadowColor: #DB6038;
@buttonPrimaryBasicActiveBackgroundColor: #ffe3c2;
@buttonPrimaryBasicActiveTextColor: #6F1F06;
@buttonDisabledBasicBackgroundColor: #EEEEEE;
@buttonNegativeBackgroundColor: #d32b43;
@buttonNegativeShadowColor: #931C38;
@buttonNegativeTextColor: white;
@buttonNegativeHoveredColor: #b11b3e;
@buttonNegativeBorderColor: #931C38;
@buttonNegativeActiveBackgroundColor: #b11b3e;
@ctaBoxBackgroundColor: #AECFD1;
@ctaBoxTextColor: black;
@progressColor: #00A0C8;
@progressBackgroundColor: #AECFD1;
@hintColor: #636363;
@pricingCellBackgroundColor: #FFEFDB;
@singleColumnBackdropColor: #00A0C8;
@orgaImprintBackgroundColor: #f8f8f8;
@darkGrayTextColor: #282828;
@lightGrayTextColor: #636363;
@flavor: me;