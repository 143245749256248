@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.single-column-layout {
  .bg-light-admin-color a {
    color: @darkPrimaryColor;
  }

  @media only screen and (min-width: 500px) {
    #me-application {
      background-color: @singleColumnBackdropColor;

      .me-header {
        border: none;
      }

      .ui.segment {
        border-radius: 3px;
        border: medium none;
        box-shadow: fade(shade(@singleColumnBackdropColor, 50%), 50%) 0px 13px 26px;
      }

      .ui.segment .ui.segment {
        box-shadow: none;
      }
    }
  }
}
